import {get, set} from '@/store/storage';
import {Http} from '@capacitor-community/http';

/**
 *
 */
export default {
    async readFiles() {
        let rooms = await get('rooms_data');
        let swaps = await get('swaps_data');
        let products = await get('products_data');

        if(rooms === null ||  swaps === null || products === null) {
            const getfiles = await this.getFiles(0).then(r => {
                return r;
            });
            return {
                rooms: getfiles.rooms,
                swaps: getfiles.swaps,
                products: getfiles.products
            };
        }

        return {
            rooms: (rooms) ? rooms.data : {},
            swaps: (swaps) ? swaps.data : {},
            products: (products) ? products.data : {}
        };
    },
    async getFiles(cache) {
        let threeOhFour = false;
        /*const urls = {
            products: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/products',
            rooms: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/rooms',
            swaps: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/swaps'
        }*/
        const rooms = await Http.get({url: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/rooms?cache='+cache, mode: 'no-cors'}).then((e) => {
            return e
        }).catch(() => {
            threeOhFour = true;
        });
        const swaps = await Http.get({url: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/swaps?cache='+cache, mode: 'no-cors'}).then((e) => {
            return e
        }).catch(() => {
            threeOhFour = true;
        });
        const products = await Http.get({url: 'https://api.savemoneycutcarbon.com/wp-json/sustainable-swaps/v1/products?cache='+cache, mode: 'no-cors'}).then((e) => {
            return e
        }).catch(() => {
            threeOhFour = true;
        });

        if(threeOhFour === true && cache !== 0) {
            return this.readFiles(false).then(r => {
                return r;
            });
        }

        await set('rooms_data', rooms);
        await set('swaps_data', swaps);
        await set('products_data', products);

        return {
            rooms: (rooms) ? rooms.data : {},
            swaps: (swaps) ? swaps.data : {},
            products: (products) ? products.data : {}
        }
    },
}
