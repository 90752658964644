<template>
    <ion-page ref="base">
        <ion-header class="ion-no-border border-b border-gray-50">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button :default-href="pageDefaultBackLink" text="" :icon="require('@/assets/Icons/arrow-back-grey.svg')" class="pl-2">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-back-button>

                </ion-buttons>
                <ion-title><ion-img :src="require('@/assets/images/SustainAbility-logo.svg')" class="w-20 mx-auto"></ion-img></ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="openTutorial(id, 'tutorial')">
                        <ion-img :src="require('@/assets/Icons/help.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :class="(room) ? 'no-scroll' : ''">
            <slot/>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonButton,
    IonTitle,
    IonImg,
    IonContent,
    IonButtons,
    modalController
} from '@ionic/vue';
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
import {get} from "@/store/storage";
export default {
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonImg,
        IonContent,
        IonBackButton,
        IonButton,
        IonButtons
    },
    props: ['pageTitle', 'pageDefaultBackLink'],
    data() {
        return {
            room: !!(this.$route.params.id),
            router: useRouter()
        }
    },
    setup() {
    },
    methods: {
        async openTutorial(id, type) {
            const modal = await modalController.create({
                component: TutorialModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        }
    },
    async mounted() {
        if(await get('tutorial') !== true) {
            return this.openTutorial();
        }
    }

}
</script>
