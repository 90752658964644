import {get} from "@/store/storage";

function getProductID(item) {
    return item.product;
}
function getSwapID(item) {
    return item.swap;
}
/*function getRoomID(item) {
    return item.room;
}*/

/*function getObject(theObject, theId) {
    let result = null;
    if(theObject instanceof Array) {
        for(let i = 0; i < theObject.length; i++) {
            result = getObject(theObject[i], theId);
            if (result) {
                break;
            }
        }
    } else {
        for(const prop in theObject) {
            if(prop === 'id') {
                if(theObject[prop] === theId) {
                    return theObject;
                }
            }
            if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObject(theObject[prop], theId);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}*/

export async function filterRoomSwaps(swaps) {
    const declines = await get('declines')

    if(declines !== null) {

        const numbers = declines.map(Number);
        return swaps.filter(function (item) {
            return numbers.indexOf(item.id) === -1;
        });

    }
    return swaps
}

export async function filterChosenProducts(products) {
    const declines = await get('declined_products') || [];
    const accepted = await get('accepted_products') || [];
    const obj = declines.concat(accepted).map(getProductID);
    if(obj !== null) {

        const numbers = obj.map(Number)
        return products.filter(function (item) {
            return numbers.indexOf(item.id);
        });
    }
    return products
}

export async function filterDeclinedSwaps(swaps) {
    const declines = await get('declined_swaps') || [];
    if(declines !== null) {

        const numbers = declines.map(Number)
        return swaps.filter(function (item) {
            return numbers.indexOf(item.room) === -1;
        });
    }
    return swaps;
}

export async function filterCompletedSwaps(swaps) {
    const declined = await get('declined_swaps') || [];
    const obj1 = declined.map(getSwapID);

    if(obj1 !== null) {

        const numbers = obj1.map(Number)
        swaps = swaps.filter(function (item) {
            return numbers.indexOf(item.id) === -1;
        });
    }
    const declines = await get('declined_products') || [];
    const accepted = await get('accepted_products') || [];

    const obj = declines.concat(accepted).map(getProductID);
    const numbers = obj.map(Number)

    let filtered = swaps.map((item) => {
        if(item.products !== null) {
            let filter = item.products.filter(function (item) {
                return numbers.indexOf(item) !== -1;
            });

            if (filter.length !== item.products.length) {
                return item
            }
        }
    });

    filtered = filtered.filter(function( element ) {
        return element !== undefined;
    });

    return filtered;
}

export async function filterMarkSwaps(swaps, id) {
    const products = await get('products_data');
    // get array of products ID's frm each swap
    // append to new array the full product with
    // status of choice/swap.

    let declines = await get('declined_products') || [];
    let accepted = await get('accepted_products') || [];

    accepted = accepted.filter(x => x.room === id);
    declines = declines.filter(x => x.room === id);

    declines = declines.map(getProductID)
    accepted = accepted.map(getProductID)

    console.log(accepted)

    accepted = accepted.map(Number)
    declines = declines.map(Number)

    return swaps.map((swap) => {
        if(swap.products !== null) {

            const filter = products.data.filter(function(item) {
               return swap.products.includes(item.id)
            });

            filter.map((item) => {
                item.declined = declines.some(element => {
                    if (element === item.id) {
                        return true
                    }
                })
                item.accepted = accepted.some(element => {
                    if (element === item.id) {
                        return true
                    }
                })
                //item.accepted = accepted.includes(item.id);
                return item
            })
            swap.fullProducts = filter;

            /*item.products.map((item) => {
                if (products.data.includes(parseInt(item))) {
                    console.log(products.title)
                }
            })*/
        }
        return swap;
    })

}

export async function filterGetAllSwaps(products) {
    let accepted = await get('accepted_products')

    if(!accepted) {
        return []
    }
    const obj = accepted.map(getProductID);
    if(obj !== null) {

        const numbers = obj.map(Number);

        return products.filter(function (item) {
            return numbers.indexOf(item.id) !== -1;
        });


    }
    return [];
}

export async function calculateProgress(rooms, swaps) {
    const declined = await get('declined_swaps') || [];
    const declines = await get('declined_products') || [];
    const accepted = await get('accepted_products') || [];
    const obj = declines.concat(accepted);

    if(!rooms) {
        return 0;
    }

    // TODO: calculate declined swaps
    const calculated = rooms.map((room) =>{
        room.products = 0;
        room.choice = 0;
        room.swaps = 0;
        room.productsComplete = 0;
        room.swapsComplete = 0;
        room.completed = 0
        swaps.map((swap) => {
            if(swap.products !== null) {
                if (swap.room_id === room.id) {
                    swap.products.map(()=> {
                        room.swaps++;
                    })


                    declined.map((choice) => {
                        if (parseInt(choice.room) === room.id) {
                            if (parseInt(choice.swap) === swap.id) {
                                room.swapsComplete++;
                            }
                        }
                    });

                    obj.map((choice) => {
                        if (parseInt(choice.room) === swap.room_id) {

                            if (swap.products.includes(parseInt(choice.product))) {
                                room.swapsComplete++;
                            }
                        }
                    });

                }
                if (room.swapsComplete && room.swaps) {
                    room.completed = room.swapsComplete / room.swaps * 100;
                }
            }
            return swap.room === room.id;
        });

        return room;
    });
    /*console.log(calculated)
    console.log(declined)*/
    return calculated;

    /*console.log(declines)
    console.log(accepted)*/

}

export async function declinedCount(room) {

    let declined = await get('declined_products') || [];
    return declined.filter((x) => {
        return x.room === room
    }).length;

}
export async function acceptedCount(room) {
    console.log(room)
    let accepted = await get('accepted_products') || [];
    return accepted.filter((x) =>  {
        return x.room === room
    }).length;

}
