import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'
import SignIn from "@/views/Signin.vue";
import Signup from "@/views/Signup.vue";
import { TokenService } from "@/services/token.service";

const routes = [
  {
    path: '/',
    redirect: '/home/rooms'
  },
  {
    path: '/home/',
    component: Tabs,
    public: false,
    children: [
      {
        path: '',
        redirect: '/home/rooms'
      },
      {
        path: '/home/rooms',
        component: () => import('../pages/RoomsPage.vue')
      },
      {
        path: '/home/rooms/:id/swaps',
        component: () => import('../pages/SwapsPage.vue')
      },
      {
        path: '/home/rooms/:id/swaps/:product/products',
        component: () => import('../pages/ProductsPage.vue')
      },
      {
        path: 'list',
        component: () => import('@/views/Tab2.vue')
      },
      {
        path: 'settings',
        component: () => import('../components/SettingsContainer.vue')
      },
      {
        path: 'settings/about-us',
        component: () => import('@/views/SettingsAbout.vue')
      },
      {
        path: 'settings/notifications',
        component: () => import('@/views/Notifications.vue')
      },
      {
        path: 'settings/help-and-support',
        component: () => import('@/views/HelpSupport.vue')
      },
      {
        path: 'settings/edit',
        component: () => import('@/views/SettingsEdit.vue')
      },
      {
        path: 'my-swaps',
        component: () => import('@/views/My-swaps.vue')
      },
      {
        path: 'settings/swap-history',
        component: () => import('@/views/SwapHistory.vue')
      },
      {
        path: 'settings/swap-history/rooms/:id',
        component: () => import('@/views/SwapHistoryRoom.vue')
      }
    ]
  },
  {
    path: '/login',
    component: SignIn,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/signup',
    component: Signup,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: '/home/rooms' }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/home/rooms");
  }

  next();
});

export default router
