<template>
    <ion-page>
    <ion-tabs class="bg-white">
        <ion-router-outlet id="main"></ion-router-outlet>
        <ion-tab-bar slot="bottom">
            <!--     Home Tab       -->
            <ion-tab-button tab="tab1" href="/home/rooms">
                <ion-icon class="w-8 h-8 text-smcc-smcc-grey" :src="require('@/assets/Icons/home.svg')" />
            </ion-tab-button>

            <!--     UNDO Action no tab       -->
            <ion-tab-button @click="undo">
                <ion-icon ref="undo" class="w-8 h-8 text-gray-300 undoBtn" :src="require('@/assets/Icons/undo.svg')" />
            </ion-tab-button>

            <!--     Swap List Tab       -->
            <ion-tab-button tab="tab3" href="/home/list">
                <ion-icon class="w-8 h-8 text-smcc-smcc-grey" :src="require('@/assets/Icons/list-heart.svg')"/>
            </ion-tab-button>

            <!--     Profile Settings Tab       -->
            <ion-tab-button tab="tab4" href="/home/settings">
                <ion-icon class="w-8 h-8 text-smcc-smcc-grey" :src="require('@/assets/Icons/settings.svg')"/>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonIcon,
    IonRouterOutlet,
    alertController
} from '@ionic/vue';
import { inject } from 'vue'
import {home, refresh, bagHandleOutline, person} from 'ionicons/icons';
import { get, remove } from '@/store/storage';

export default {
    name: 'Tabs',
    components: {
        IonPage,
        IonTabs,
        IonTabBar,
        IonTabButton,
        IonIcon,
        IonRouterOutlet,
    },
    data() {
        const emitter = inject("emitter"); // Inject `emitter`

        return {
            emitter,
        }
    },
    setup() {

        return {
            home,
            refresh,
            bagHandleOutline,
            person
        }
    },
    methods: {
        async undo() {
            const action = await get('last_action');
            if(action === null) {
                return;
            }
            let subHeader = '';
            let theUndoneProduct = '';
            let products = [];
            let swaps = [];
            switch(action.set) {
                case 'accepted_products':
                    products = await get('products_data');
                    theUndoneProduct = products.data.filter((item) => {
                        if(item.id === parseInt(action.value.product)) {
                            return item
                        }
                    })[0]
                    subHeader = `Are you sure you want to undo your swap of <strong>${theUndoneProduct.name}</strong>`
                break;
                case 'declined_products':
                    products = await get('products_data');
                    theUndoneProduct = products.data.filter((item) => {
                        if(item.id === parseInt(action.value.product)) {
                            return item
                        }
                    })[0]
                    subHeader = `Are you sure you want to undo your reject of <strong>${theUndoneProduct.name}</strong>`
                break;
                case 'declined_swaps':
                    swaps = await get('swaps_data');
                    theUndoneProduct = swaps.data.filter((item) => {
                        if(item.id === parseInt(action.value.swap)) {
                            return item
                        }
                    })[0]
                    subHeader = `Are you sure you want to undo your reject of <strong>${theUndoneProduct.title}</strong>`
                break;
            }

            const alert = await alertController
                .create({
                    cssClass: 'basic-alert',
                    header: 'Undo',
                    message: subHeader,
                    buttons: [
                        {
                            text: 'Yes',
                            handler: () => {
                                this.emitter.emit("undo", 100);
                                this.$refs.undo.$el.classList.remove('text-smcc-smcc-green');
                                remove("last_action");
                            },
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            handler: (blah) => {
                                console.log('Confirm Cancel:', blah)
                            },
                        },
                    ],
                });
            return alert.present();
        }
    },
    mounted() {
        this.emitter.on("action", async () => {   // *Listen* for event
            this.$refs.undo.$el.classList.add('text-smcc-smcc-green');
        });
    }
}
</script>

<style scoped>
ion-icon {

    --ionicon-stroke-width: 48px;
}
.tab-selected ion-icon {
    @apply text-smcc-smcc-green
}
</style>
<style>
.basic-alert {
    --backdrop-opacity: 0.6;
    --background: black;
}
.alert-title.sc-ion-alert-md, .alert-title.sc-ion-alert-ios {
    @apply text-white text-left text-sm font-normal
}
.alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    @apply text-white text-left
}
.alert-button-group.sc-ion-alert-ios, .alert-button-group.sc-ion-alert-md {
    @apply p-4 gap-2 justify-items-center
}
.alert-button.sc-ion-alert-md, .alert-button.sc-ion-alert-ios {
    border:inherit;
    min-width: auto;
    @apply text-white bg-black border border-b-2 border-t-2 border-l-2 border-r-2 border-white rounded-sm;
}
.alert-button.sc-ion-alert-md:last-child, .alert-button.sc-ion-alert-ios:last-child {
    border-right: inherit;
    @apply border-r-2 border-white font-normal
}
</style>
