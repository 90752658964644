<template>
    <ion-page class="bg-green-500 h-screen flex items-center justify-center w-full">
        <!--<form @submit.prevent="handleSignup" class="bg-green-500 h-screen flex items-center justify-center w-full">-->
            <ion-card class="bg-green-500 shadow-none w-full">
                <ion-img :src="require('@/assets/images/SustainAbility-logo-white.svg')" class="w-45 mx-auto mb-5"></ion-img>
                <ion-item lines="none" class="flex" style="--background:#76BD1D">
                    <ion-button type="button" fill="clear" style="color:#ffffff; --box-shadow:none; font-size:0.813rem; text-transform:none;" class="bg-green-500 border border-white w-6/12 shadow-none m-0" size="large" router-link="/login" expand="full">
                        Login
                    </ion-button>
                    <ion-button type="submit" fill="clear" style="color:#76BD1D; --box-shadow:none; font-size:0.813rem; text-transform:none;" class="border border-white w-6/12 m-0 bg-white" size="large" expand="full">
                        Register
                    </ion-button>
                </ion-item>

                 <ion-item lines="none" style="--background:#76BD1D">
                    <p class="text-xs text-white my-5">Please register for a SaveMoneyCutCarbon account on our website. After registration open the app and enter your login details.</p>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-button fill="clear" style="color:#ffffff; --box-shadow:none; font-size:1rem; text-transform:none;" class="bg-green-500 border-2 w-full border-white shadow-none rounded h-15" size="large" expand="full" href="https://www.savemoneycutcarbon.com/new-account/">
                        Register Online<ion-icon :src="require('@/assets/Icons/arrow-forward.svg')" class="h-6 w-6 py-6 px-1"></ion-icon>
                    </ion-button>
                </ion-item>

                <!--<ion-item lines="none" style="--background:#76BD1D" class="mt-5">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">First Name</ion-label>
                    <ion-input v-model="form.firstName" id="firstName" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded border-b border-white mt-2.5"></ion-input>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">Last Name</ion-label>
                    <ion-input v-model="form.lastName" id="lastName" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded border-b border-white mt-2.5"></ion-input>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">Email</ion-label>
                    <ion-input v-model="form.email" id="email" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded border-b border-white mt-2.5"></ion-input>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">Password</ion-label>
                    <ion-input type="password" v-model="form.password" id="email" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded border-b border-white mt-2.5"></ion-input>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">What type of Customer are you?</ion-label>
                    <ion-select value="domestic" ok-text="Okay" cancel-text="Dismiss" v-model="form.customerType" placeholder="Select One" id="customerType" class="bg-green-600 rounded border-b border-white mt-2.5" style="--padding-start: 0.5rem; color:#ffffff">
                        <ion-select-option value="Domestic">Domestic</ion-select-option>
                        <ion-select-option value="Business">Trade</ion-select-option>
                    </ion-select>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-button type="submit" fill="clear" style="color:#ffffff; --box-shadow:none; font-size:1rem; text-transform:none;" class="bg-green-500 border-2 w-full border-white shadow-none mt-7.5 rounded h-15" size="large" router-link="/signup" expand="full">
                        Create My Account
                        <ion-img :src="require('@/assets/Icons/arrow-forward-white.svg')" class="w-5"></ion-img>
                    </ion-button>
                </ion-item>
                <ion-item lines="none" style="--background:#76BD1D">
                    <a href="/login" class="text-sm text-white underline w-full text-center">Already have an account? - Login</a>
                </ion-item>-->
            </ion-card>
        <!--</form>-->
    </ion-page>
</template>

<script>
//import {IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput, IonSelect, IonSelectOption, alertController, IonImg } from '@ionic/vue'
import {IonPage, IonCard, IonItem, IonButton, alertController, IonIcon, IonImg} from '@ionic/vue'
import {logIn, personAdd} from 'ionicons/icons';
import { mapGetters} from "vuex"
import {Http} from '@capacitor-community/http';

export default {
    name: 'Signup',
    //components: {IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput, IonSelect, IonSelectOption, IonImg },
    components: {IonPage, IonCard, IonItem, IonButton, IonIcon, IonImg},
    setup() {
        return {
            logIn,
            personAdd
        };
    },
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                customerType: ""
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["authenticationError", "authenticationErrorCode"])
    },
    methods: {
        async handleSignup() {

            if (
                this.form.firstName &&
                this.form.lastName &&
                this.form.email &&
                this.form.password &&
                this.form.customerType
            ) {
                //Setup Data Object
                const registerUser = {
                    first_name: this.form.firstName,
                    last_name: this.form.lastName,
                    email: this.form.email,
                    password: this.form.password,
                    meta_data: [
                        {
                            key: "_origin",
                            value: "sustain-ability-app"
                        },
                        {
                            key: "customer_type",
                            value: this.form.customerType
                        }
                    ]
                };

                //WooCommerce API Key / Secret
                let username = 'ck_9c758d8b2586079351ed937d9f9c54841f5b4ebb';
                let password = 'cs_7e4cad3cdb4f20780390ad272e3b95ad8e1c609c';
                let headers = new Headers();
                let authString = `${username}:${password}`;
                headers.set('Authorization', 'Basic ' + btoa(authString));
                headers.set('Content-Type', 'application/json');

                const options = {
                    url: 'https://www.savemoneycutcarbon.com/sustain-ability/customer.php',
                    mode: 'no-cors',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: 'Basic ' + btoa(authString)
                    },
                    data: registerUser,
                }

                await Http.post(options).then((r) => {
                    if(r.id){
                        this.presentSignupSuccess();
                        console.log('Success');
                    }else{
                        this.presentSignupError();
                        console.log('Error');
                    }
                    console.log(r);

                }).catch((err) => {
                    console.log(err)
                })
            } else {
                const errorAlert = await alertController
                    .create({
                        header: 'Failed',
                        subHeader: 'Signup Failed',
                        message: 'You have not filled in the form completely.',
                        buttons: ['OK'],
                    });
                await errorAlert.present
            }
        },
        async presentSignupSuccess(){
            const alert = await alertController
                .create({
                    header: 'Success',
                    subHeader: 'Signup Success',
                    message: 'Your account has been successfully created. Please proceed to the login form and enter your details.',
                    buttons: ['OK'],
                });
            return alert.present()
        },
        async presentSignupError(){
            const errorSignupAlert = await alertController
            .create({
                header: 'Failed',
                subHeader: 'Signup Failed',
                message: 'There was an issue communicating with the server. please try again.',
                buttons: ['OK'],
            });
            return errorSignupAlert.present
        }

    }
}
</script>

<style scoped>

</style>
