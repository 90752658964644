//import axios from "axios";
import ApiServices from "./api.services";
import { TokenService } from "./token.service";
import {set} from '@/store/storage';
import qs from "qs";

export class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.message = message;
        }
        this.errorCode = errorCode;
    }
}

const AuthService = {
    signIn: async function(signInData) {
        const requestData = {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
            },
            url: "/?oauth=token",
            data: qs.stringify({
                "grant_type": "password",
                username: signInData.username,
                password: signInData.password
            })
        };

        try {
            const response = await ApiServices.customRequest(requestData);
            TokenService.saveToken(response.data.access_token);
            TokenService.saveRefreshToken(response.data.refresh_token);
            ApiServices.setHeader();

            ApiServices.mount401Interceptor();

            await set('user', this.getUser());

            return response.data.access_token;
        } catch (error) {
            this.catchError(error);
        }
    },

    async getUser() {
        const token = TokenService.getToken();
        const url = 'https://www.savemoneycutcarbon.com?oauth=me&access_token='+token;
        let response = await fetch(url);

        if (response.ok) { // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();
            console.log(json);
            await set('user_data', json);
            return json;
        } else {
            await this.refreshToken().then(r => {
                console.log(r);
            });
            console.log("HTTP-Error: " + response.status);
            return {}
        }
        //
    },

    refreshToken: async function() {
        const refreshToken = TokenService.getRefreshToken();

        const requestData = {
            method: "post",
            url: "/oauth/token",
            headers: {
                Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
            },
            data: qs.stringify({
                "grant_type": "refresh_token",
                "refresh_token": refreshToken
            })
        };

        try {
            const response = await ApiServices.customRequest(requestData);
            console.log(response)
            TokenService.saveToken(response.data.access_token);
            TokenService.saveRefreshToken(response.data.refresh_token);
            ApiServices.setHeader();

            return response.data.access_token;
        } catch (error) {
            throw new AuthenticationError(
                error.response.status,
                error.response.data.error_description
            );
        }
    },

    signOut() {
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        ApiServices.removeHeader();
        ApiServices.unmount401Interceptor();
    },

    signup: async function(email, password, name) {
        const signupData = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: "/oauth/signup",
            data: {
                email: email,
                password: password,
                name: name
            }
        };

        try {
            return await ApiServices.customRequest(signupData);
        } catch (error) {
            this.catchError(error);
        }
    },

    catchError: function(error) {
        let status;
        let description;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data.error_description;
        }

        throw new AuthenticationError(status, description);
    }
}

export { AuthService };
