<template>
    <ion-page>
        <form @submit.prevent="handleLogin" class="bg-green-500 h-screen flex items-center justify-center">
            <ion-card class="bg-green-500 shadow-none">
                <ion-img :src="require('@/assets/images/SustainAbility-logo-white.svg')" class="w-45 mx-auto mb-5"></ion-img>
                <ion-item lines="none" class="flex" style="--background:#76BD1D">
                    <ion-button type="submit" fill="clear" style="color:#76BD1D; --box-shadow:none; font-size:0.813rem; text-transform:none;" class="border border-white w-6/12 m-0 bg-white" size="large" expand="full">
                        Login
                    </ion-button>
                    <ion-button type="button" fill="clear" style="color:#ffffff; --box-shadow:none; font-size:0.813rem; text-transform:none;" class="bg-green-500 border border-white w-6/12 shadow-none m-0" size="large" router-link="/signup" expand="full">
                        Register
                    </ion-button>
                </ion-item>
                <div class="px-6">
                    <p class="text-xs text-white my-5">Login with your existing SaveMoneyCutCarbon account and your swaps will be shared with the website.</p>
                    <p class="text-xs text-white my-5">Haven't got an account? Click 'Register' to begin your sustainability journey...</p>
                </div>
                <ion-item lines="none" style="--background:#76BD1D" class="mb-3">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">Username or email address</ion-label>
                    <ion-input v-model="form.username" id="username" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded border-b border-white mt-2.5"></ion-input>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D" class="relative">
                    <ion-label position="stacked" style="--color:#ffffff; font-size: 0.938rem;" class="text-xs text-white">Password</ion-label>
                    <ion-input :type="passwordFieldType" v-model="form.password" id="password" required style="--padding-start: 0.5rem; color:#ffffff" class="bg-green-600 rounded w-full border-b border-white mt-2.5"></ion-input>
                    <button type="button" clear @click="switchVisibility" class="absolute right-4 top-11 z-50 eye">
                        <ion-icon :icon="(passwordFieldType === 'password') ?  eyeOffOutline : eyeOutline" class="text-white w-10" size="large"></ion-icon>
                    </button>
                </ion-item>

                <ion-item lines="none" style="--background:#76BD1D">
                    <ion-button type="submit" fill="clear" style="color:#ffffff; --box-shadow:none; font-size:1rem; text-transform:none;" class="bg-green-500 border-2 w-full border-white shadow-none mt-7.5 rounded h-15" size="large" expand="full" >
                        Login
                        <ion-img :src="require('@/assets/Icons/arrow-forward-white.svg')" class="w-5"></ion-img>
                    </ion-button>
                </ion-item>
            </ion-card>
        </form>
    </ion-page>
</template>

<script>
import {IonPage, IonImg, IonCard, IonItem, IonLabel, IonButton, IonInput, alertController, IonIcon} from '@ionic/vue'
import {logIn, personAdd, eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex"
import {useRouter} from 'vue-router';

export default {
    name: 'SignIn',
    components: {IonPage, IonImg, IonCard, IonItem, IonLabel, IonButton, IonInput, IonIcon},
    setup() {
        const router = useRouter();
        return {
            router,
            logIn,
            personAdd,
        };
    },
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            passwordFieldType: "password",
            eyeOutline,
            eyeOffOutline
        };
    },
    computed: {
        ...mapGetters("auth", [
            "authenticating",
            "authenticationError",
            "authenticationErrorCode"
        ])
    },
    methods: {
        ...mapActions("auth", ["signIn"]),
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        async handleLogin() {
            this.signIn(this.form).then(() => {
                this.form.username = ""
                this.form.password = ""
                this.router.push("/home/rooms")
            }).catch(async (err) => {
                const errorAlert = await alertController
                    .create({
                        cssClass: 'basic-alert',
                        header: 'Login Failed',
                        message: err,
                        buttons: ['OK'],
                    });
                await errorAlert.present()
            })
        }
    }
}
</script>

<style scoped>
.md .eye {
    @apply top-8
}
.basic-alert {
    --backdrop-opacity: 0.6;
    --background: black;
}
.alert-title.sc-ion-alert-md, .alert-title.sc-ion-alert-ios {
    @apply text-white text-left text-sm font-normal
}
.alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    @apply text-white text-left
}
.alert-button-group.sc-ion-alert-ios, .alert-button-group.sc-ion-alert-md {
    @apply p-4 gap-2 justify-items-center
}
.alert-button.sc-ion-alert-md, .alert-button.sc-ion-alert-ios {
    border:inherit;
    min-width: auto;
    @apply text-white bg-black border border-b-2 border-t-2 border-l-2 border-r-2 border-white rounded-sm;
}
.alert-button.sc-ion-alert-md:last-child, .alert-button.sc-ion-alert-ios:last-child {
    border-right: inherit;
    @apply border-r-2 border-white font-normal
}
</style>
