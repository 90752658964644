import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import BaseLayout from "@/components/base/BaseLayout";
import { IonicVue } from '@ionic/vue';

import { SplashScreen } from '@capacitor/splash-screen';

import ApiServices from "@/services/api.services";
ApiServices.init(process.env.VUE_APP_ROOT_API);


import mitt from 'mitt';                  // Import mitt
const emitter = mitt();                   // Initialize mitt

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import './assets/styles/tailwind.css';

import {TokenService} from "@/services/token.service";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store);

app.provide('emitter', emitter);          // ✅ Provide as `emitter`

if (TokenService.getToken()) {
    ApiServices.setHeader();
    ApiServices.mountRequestInterceptor();
    ApiServices.mount401Interceptor();
}

app.component('base-layout', BaseLayout);

router.isReady().then(async () => {
    await SplashScreen.show({
        showDuration: 3000,
        autoHide: true
    });
  app.mount('#app');
});
