<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button>
                       <span class="w-6 h-6"></span>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">How it works</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal()">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content fullscreen class="ion-padding" scroll-y="false">

            <swiper class="swiper" parallax :pagination="{ clickable: true }" ref="mySwiper" :loop="false" :initialSlide="0" :navigation="{ nextEL: '.swiper-button-next', prevEL: '.swiper-button-prev' }">
                <swiper-slide class="flex items-center w-full">
                    <div class="slide mx-7.5 -mt-32">
                        <div class="flex items-center w-full justify-center mb-5">
                            <ion-img :src="require('@/assets/Icons/swap-sad.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                            <ion-img :src="require('@/assets/Icons/arrow-forward.svg')" class="w-5"></ion-img>
                            <ion-img :src="require('@/assets/Icons/swap-happy.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                        </div>
                    <p class="text-md text-gray-900 text-center mb-7.5 leading-relaxed">Let us guide you in your sustainability journey. Through this app we will help you identify everyday products you use at home and offer you a sustainable alternative. </p>
                    <p class="text-md text-gray-900 text-center leading-relaxed">Whether you’re looking to reduce your energy consumption, use less water or simply cutdown on single use plastic we will help you to Save Money and Cut Carbon.</p>
                    </div>
                    <div class="swiper-next absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto">
                        <p class="text-green-500 text-base">Continue</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                    <div class="absolute bottom-6 w-full text-center ">
                        <ion-button fill="clear" @click="closeModal()" class="text-sm p-0 text-smcc-smcc-green underline">Skip</ion-button>
                    </div>
                </swiper-slide>

                <swiper-slide class="flex items-center justify-center">
                    <div class="slide mx-7.5 -mt-32">
                        <div class="flex items-center w-full justify-center mb-5">
                            <ion-img :src="require('@/assets/Icons/home-green.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                        </div>
                        <p class="text-md text-gray-900 text-center leading-relaxed">To start your journey all you need to do is choose the first room in your home you would like to make more sustainable…</p>
                    </div>
                    <div class="swiper-next absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto">
                        <p class="text-green-500 text-base">Continue</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                    <div class="absolute bottom-6 w-full text-center ">
                        <ion-button fill="clear" @click="closeModal()" class="text-sm p-0 text-smcc-smcc-green underline">Skip</ion-button>
                    </div>
                </swiper-slide>

                <swiper-slide class="flex items-center justify-center">
                    <div class="slide w-full mx-7.5 -mt-32">
                        <p class="text-center text-md text-gray-900 mb-12.5 leading-relaxed">You’ll now be presented with an everyday item in your home that could be swapped to something more environmentally friendly.</p>
                        <div class="flex items-center w-full justify-between mb-7.5">
                            <ion-img :src="require('@/assets/Icons/colour-circle-cross.svg')"></ion-img>
                            <ion-img :src="require('@/assets/Icons/screen-swipe.svg')"></ion-img>
                            <ion-img :src="require('@/assets/Icons/colour-circle-arrow.svg')"></ion-img>
                        </div>
                        <div class="flex justify-between">
                            <p class="mr-7.5 text-md text-gray-900 w-4/12 leading-relaxed">Swipe left if you do not use this product and move on to the next one</p>
                            <p class="ml-7.5 text-right text-md text-gray-900 w-4/12 leading-relaxed">Swipe right to select and begin the swapping journey</p>
                        </div>
                    </div>
                    <div class="swiper-next absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto">
                        <p class="text-green-500 text-base">Continue</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                    <div class="absolute bottom-6 w-full text-center ">
                        <ion-button fill="clear" @click="closeModal()" class="text-sm p-0 text-smcc-smcc-green underline">Skip</ion-button>
                    </div>
                </swiper-slide>

                <swiper-slide class="flex items-center justify-center">
                    <div class="slide w-full mx-7.5 -mt-32">
                        <p class="text-center text-md text-gray-900 mb-12.5 leading-relaxed">Swiped right? You’ll now be presented with a selection of sustainable swaps.</p>
                        <div class="flex items-center w-full justify-between mb-7.5">
                            <ion-img :src="require('@/assets/Icons/colour-circle-cross.svg')"></ion-img>
                            <ion-img :src="require('@/assets/Icons/screen-swipe.svg')"></ion-img>
                            <ion-img :src="require('@/assets/Icons/colour-circle-heart.svg')"></ion-img>
                        </div>
                        <div class="flex justify-between">
                            <p class="mr-7.5 text-md text-gray-900 w-4/12 leading-relaxed">Swipe left to reject the swap</p>
                            <p class="ml-7.5 text-right text-md text-gray-900 w-4/12 leading-relaxed">Swipe right to add the product to your swap list</p>
                        </div>
                    </div>
                    <div class="swiper-next absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto">
                        <p class="text-green-500 text-base">Continue</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                    <div class="absolute bottom-6 w-full text-center ">
                        <ion-button fill="clear" @click="closeModal()" class="text-sm p-0 text-smcc-smcc-green underline">Skip</ion-button>
                    </div>
                </swiper-slide>

                <swiper-slide class="flex items-center justify-center">
                    <div class="slide mx-7.5 -mt-32">
                        <div class="flex items-center w-full justify-center mb-5">
                            <ion-img :src="require('@/assets/Icons/list-heart-green.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                        </div>
                        <p class="text-md text-gray-900 text-center mb-7.5 leading-relaxed">Can’t decide which item to swap to?</p>
                        <p class="text-md text-gray-900 text-center leading-relaxed">Easy, just select multiple products and they will all be added to your swap list and you can make your mind up later!</p>
                    </div>
                    <div class="swiper-next absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto">
                        <p class="text-green-500 text-base">Continue</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                    <div class="absolute bottom-6 w-full text-center ">
                        <ion-button fill="clear" @click="closeModal()" class="text-sm p-0 text-smcc-smcc-green underline">Skip</ion-button>
                    </div>
                </swiper-slide>

                <swiper-slide class="flex items-center justify-center">
                    <div class="slide mx-7.5 -mt-32">
                        <div class="flex items-center w-full justify-center mb-5">
                            <ion-img :src="require('@/assets/Icons/list-heart-grey.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                            <ion-img :src="require('@/assets/Icons/arrow-forward.svg')" class="w-5"></ion-img>
                            <ion-img :src="require('@/assets/Icons/website-basket-green.svg')" class="w-10 h-10 mx-1.5"></ion-img>
                        </div>
                        <p class="text-md text-gray-900 text-center mb-7.5 leading-relaxed">What do I do now?</p>
                        <p class="text-md text-gray-900 text-center leading-relaxed">All of the products you have chosen will be added to your Swaps List which is accessible via your account on
                        <a class="text-md text-center text-smcc-smcc-green mb-7.5 inline-block underline" href="https://www.savemoneycutcarbon.com/" onclick='window.open(this.href, "_system", "location=yes"); return false;'>www.savemoneycutcarbon.com</a></p>
                        <p class="text-md text-gray-900 text-center leading-relaxed">If you want to find out more about these amazing products or you're ready to buy and continue your sustainability journey just click 'View &amp; Buy Online' at any point throughout the app.</p>
                    </div>
                    <div class="absolute bottom-20 border-2 border-green-500 rounded flex justify-center items-center h-15 w-10/12 m-auto z-50" @click="closeModal()">
                        <p class="text-green-500 text-base">Start Swapping</p>
                        <ion-img :src="require('@/assets/Icons/arrow-forward-green.svg')" class="w-5"></ion-img>
                    </div>
                </swiper-slide>

            </swiper>

        </ion-content>
    </ion-page>
</template>

<script>
import {IonPage,
        IonButtons,
        IonButton, 
        IonHeader, 
        IonTitle, 
        IonToolbar, 
        IonContent,
        IonImg,
        IonicSwiper,
        modalController
        } from '@ionic/vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import {set} from "@/store/storage";
// import Flickity from 'vue-flickity';

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

export default {
    name: 'Tutorial',
    components: {IonPage,
                IonButtons,
                IonButton, 
                IonHeader, 
                IonTitle, 
                IonToolbar, 
                IonContent,
                IonImg,
                Swiper,
                SwiperSlide
                //Flickity
                },
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    setup() {
        const swiper = new SwiperCore('.swiper', {
            modules: [Pagination, Navigation],
            effect: 'slide',
            direction: 'horizontal',
            speed: 500,
            allowSlideNext: true,
            cssMode: true,
            noSwiping: true,
            loop: false
        })
        const closeModal = () => {
            modalController.dismiss();
        };
        set('tutorial', true)

        return {
            closeModal,
            swiper
        };
    },
}
</script>

<style>

/*! purgecss start ignore */
.swiper .swiper-next{
    left: 50%;
    transform: translateX(-50%);
}
.swiper .swiper-button-next{
    right: 0;
    left: 0;
    top: auto;
    @apply bottom-20 w-full h-15 z-50
}
.swiper-button-next:after{
    content: none;
}
.swiper-button-prev{
    top: 15px;
    left: 15px;
    @apply w-8 h-8 hidden
}
.swiper-container{
    @apply h-full
}
.swiper-pagination {
    @apply top-3 h-10
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    @apply mx-4
}
.swiper-pagination-bullet {
    @apply w-3.5 h-3.5 border border-white opacity-60 bg-black mx-2.5
}
.swiper-pagination-bullet {
    margin-left: 10px!important;
    margin-right: 10px!important;
}
.swiper-pagination-bullet-active {
    @apply bg-white border-gray-700
}
/*! purgecss end ignore */
ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}
</style>
