import axios from "axios";
import store from '../store';
import {TokenService} from "./token.service";
import {loadingController} from '@ionic/vue';

const ApiServices = {
    _requestInterceptor: 0,
    _401interceptor: 0,

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },
    basicAuth(username, password) {
        axios.defaults.headers.common[
            "Authorization"
            ] = `Basic ${btoa(unescape(encodeURIComponent(username + ':' + password)))}}`;
    },

    setHeader() {
        axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${TokenService.getToken()}`;
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource) {
        return axios.get(resource);
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    customRequest(data) {
        return axios(data);
    },
    mountRequestInterceptor() {
        this._requestInterceptor = axios.interceptors.request.use(async config => {
            const loading = await loadingController.create({
                message: 'Sigining in...'
            });
            await loading.present();
            this.isLoading = true;

            return config;
        });
    },

    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            response => {
                loadingController.dismiss().then(r => console.log(r));
                return response;
            },
            async error => {
                loadingController.dismiss().then(r => console.log(r));
                if (error.request.status === 401) {
                    if (error.config.url.includes("oauth/token")) {
                        await store.dispatch("auth/signOut");
                        loadingController.dismiss().then(r => console.log(r));
                        throw error;
                    } else {
                        try {
                            await store.dispatch("auth/refreshToken");
                            return this.customRequest({
                                method: error.config.method,
                                url: error.config.url,
                                data: error.config.data
                            });
                        } catch (e) {
                            loadingController.dismiss().then(r => console.log(r));
                            throw error;
                        }
                    }
                }
                loadingController.dismiss().then(r => console.log(r));
                throw error;
            },
        );
        if(this.isLoading === true) {
            loadingController.dismiss().then(r => console.log(r))
        }
    },

    unmount401Interceptor() {
        axios.interceptors.response.eject(this._401interceptor);
    }
}

export default ApiServices;
