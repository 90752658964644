import {createStore} from 'vuex';
import {auth} from "./auth.store.js";
import {home} from "./home.store.js";
import files from "@/data/filesystem";
import {
    filterGetAllSwaps,
    filterChosenProducts,
    filterCompletedSwaps,
    calculateProgress,
    filterMarkSwaps,
    declinedCount,
    acceptedCount
} from "@/store/methods";
//import {loadingController} from "@ionic/vue";


const store = createStore( {
    mutations: {},
    actions: {
        importData(data) {
            this.data = data;
        }
    },
    modules: {
        auth,
        home
    },
    state: {
        posts: [],
        data: files.readFiles()
    },
    getters: {
        /**
         * Filter products for the given swap
         * Return based on what has been declined or accepted
         *
         * @param state
         * @returns {function(*=): Promise<*>}
         */
        filterDeclinesAccepteds: (state) => async (id) => {
            const productsData = (await state.data).products;
            const products = productsData.filter(products => products.swap_id.includes(parseInt(id)));
            return filterChosenProducts(products);
        },
        /**
         * Filter Swaps based on how many products are chosen
         * If no products are left return nothing, effectively
         * removing the swap from the list.
         *
         * @param state
         * @returns {function(): Promise<*>}
         */
        filterSwaps: (state) => async (id) => {
            const swapsData = (await state.data).swaps;
            const swaps = swapsData.filter(swaps => swaps.room_id === parseInt(id));
            return filterCompletedSwaps(swaps);
        },
        swapHistory: (state) => async (id) => {
            const swapsData = (await state.data).swaps;
            const swaps = swapsData.filter(swaps => {
                return swaps.room_id === parseInt(id)
            });
            console.log(swaps)
            return filterMarkSwaps(swaps, id);
        },
        declinedCount: () => async (id) => {
            return declinedCount(id)
        },
        acceptedCount: () => async (id) => {
            return acceptedCount(id)
        },
        filterBySwaps: (state) => async () => {
            const productsData = (await state.data).products;
            return filterGetAllSwaps(productsData);
        },
        rooms: (state) => async () => {
            const data = (await state.data).rooms;
            const swaps = (await state.data).swaps;
            await calculateProgress(data, swaps)
            return data;
        },
        room: (state) => async (roomId) => {
            const data = (await state.data).rooms;
            return data.find(room => room.id === roomId);
        },
        swap() {
            return (swapId) => {
                /**
                 * Return contents of current category
                 */
                const target_copy = Object.assign({}, swapId.roomId.swapCategories);
                console.log(swapId)
                console.log(target_copy);
                console.log(swapId.roomId.swapCategories.find(x => x.id === Number(swapId.swap)));
                return swapId.roomId.swapCategories.find(x => x.id === Number(swapId.swap));

            }
        }
    }
});

export default store;
